import { Strings } from "@/resources";
import { Head, Link } from "@inertiajs/react";

export default function ErrorPage({ status }: { status: number }) {
  const language = Strings.error_page;
  const title = {
    403: language[403].title,
    404: language[404].title,
    500: language[500].title,
    503: language[503].title,
  }[status];

  const description = {
    403: language[403].description,
    404: language[404].description,
    500: language[500].description,
    503: language[503].description,
  }[status];

  return (
    <div className="error-page form-screens">
      <Head title={title} />
      <div className="error-content">
        <h1 className="error-title">{title}</h1>
        <p className="error-desc">{description}</p>
        <Link href="/" className="btn" style={{ marginTop: "20px" }}>
          {language.go_back_text}
        </Link>
      </div>
    </div>
  );
}
